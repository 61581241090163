/* * {
    border: red 2px dashed;
} */

hr {
    width: 80%;
    border: 1px solid rgb(140, 140, 140);
}

.wholesite-body {
    background-color: rgb(60, 60, 60);
    color: whitesmoke;
    min-height: 100vh;
}

.header {
    background-color: rgb(27, 27, 27);
    color: whitesmoke;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
}

.inner-head{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.header-link{
    font-weight: bold;
    font-size: medium;
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: whitesmoke;
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.header-button{
    font-size: large;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;
    border: none;
    background-color: rgba(0, 0, 0);
    color: aliceblue;
    filter: drop-shadow(0px 0px 8px rgb(118, 118, 118));
}

.title {
    text-align: center;
}

.poster-board {
    background-image: url("../img/demo2.webp");
    min-height: 700px;
    background-position: center;
    background-size: 1900px 1300px;
    display: flex;
}

.poster-text-box {
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-left: 10%;
    margin-top: 5%;
    margin-right: 20%;
    margin-bottom: 15%;
}

.site-body {
    display: flex;
    justify-content: center;
    align-items: center;

}

.body-img {
    border: white 4px solid;
}

.body-text {
    padding-left: 3%;
}

.body-text-text {
    font-size: large;
}

.site-gallery {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.gallery-scroll {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.side-button {
    padding: 15px;
    padding-top: 70px;
    padding-bottom: 70px;
    margin: 20px;
    border-radius: 50px;
    border: none;
    background-color: rgba(0, 0, 0);
    color: aliceblue;
    filter: drop-shadow(0px 0px 8px rgb(118, 118, 118));
  }


  .right-button {
    position: absolute;
    right: 8%;
  }

  .right-button:hover{
    right: 7.7%;
  }

  .left-button {
    position: absolute;
    left: 8%;
  }

  .left-button:hover{
    left: 7.7%;
  }

.contact {
    background-color: rgb(27, 27, 27);
    color: whitesmoke;
    min-height: 400px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.contact-mobile {
    background-color: rgb(27, 27, 27);
    color: whitesmoke;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.contact-inner {
    font-size: inherit;
    border: white 2px solid;
    padding: 20px;
    border-radius: 20px;
}


.the-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.small-image {
    width: 400px;
    border: 2px white solid;
    margin: 10px;
}
